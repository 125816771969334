import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress
} from "@mui/material";
import {styled} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import {ApiInstance} from "../../api/api";
import s from "./printerForm/PrinterForm.module.css";
import {printerDataType} from "../../types/printerTypes";

export type printerStatusType = {
  id: number;
  status: boolean;
  printer_name: string;
  ip: string;
  serial: string;
  domain: string;
};

export interface InitialDeleteFormProps {
  selectedEntity: printerDataType[] | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1)
  }
}));

const modalButtonStyle = {
  margin: "10pt",
  height: "50pt",
  whiteSpace: "nowrap",
  padding: "15px 15px",
  fontSize: "15pt"
};

function PrinterStatusTable({printerStatus}: { printerStatus: printerStatusType[] }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Printer Name</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printerStatus.map((printer) => (
            <TableRow key={printer.id}>
              <TableCell
                sx={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {printer.printer_name}
              </TableCell>
              <TableCell sx={{color: printer.status ? "green" : "red"}}>
                {printer.status ? "Success" : "Failed"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function DeletePrintersForm({
                                             selectedEntity,
                                             isOpen,
                                             onClose
                                           }: InitialDeleteFormProps) {
  const [isShowAskDialog, setIsShowAskDialog] = useState<boolean>(true);
  const [printerStatus, setPrinterStatus] = useState<printerStatusType[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRetryLoading, setIsRetryLoading] = useState(false);
  const [{title, subTitle}, setDialogText] = useState({
    title: "",
    subTitle: ""
  });

  useEffect(() => {
    if (isOpen) {
      setIsShowAskDialog(true);
      setPrinterStatus(null);
      setDialogText({
        title: "Are you sure you want to delete this printer(s)?",
        subTitle: "Upon deletion, printer will stop working with the printing solution immediately!"
      });
      setIsLoading(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    setIsShowAskDialog(false);
    setIsLoading(true);
    try {
      if (selectedEntity) {
        const res = await ApiInstance.deletePrinters(selectedEntity);
        setPrinterStatus(res.printers);
        setDialogText({
          title: "Printer was successfully deleted",
          subTitle: ""
        });
      }
    } catch (error) {
      setDialogText({
        title: "Error Deleting Printer",
        subTitle: "Something went wrong while deleting the printer(s)."
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRetry = async () => {
    if (!printerStatus) return;

    // const failedPrinters = printerStatus.filter(printer => !printer.status);
    const failedPrinters: printerDataType[] = printerStatus
      .filter(printer => !printer.status)
      .map(printer => ({
        id: printer.id,
        name: printer.printer_name,  // Assuming name maps from printer_name
        address: printer.ip,         // If address is equivalent to IP
        deviceSerial: printer.serial, // Assuming serial corresponds to deviceSerial
        building_name: printer.domain, // Assuming domain is building_name
        city_name: "",
        country_name: "",
        region_name: "",
        status: "",
        last_updated: "",
        document_output: "",
        terminal_client: "",
        embeddedConfigurationId: 0
      }));
    if (failedPrinters.length === 0) return;

    setIsRetryLoading(true);
    try {
      // Call the same API endpoint with the failed printers only.
      const res: { printers: printerStatusType[] } = await ApiInstance.deletePrinters(failedPrinters);

      // Merge new statuses with the existing ones.
      const updatedStatus = printerStatus.map(printer => {
        const retried = res.printers.find(p => p.id === printer.id);
        return retried ? retried : printer;
      });
      setPrinterStatus(updatedStatus);

      if (updatedStatus.every(printer => printer.status)) {
        setDialogText({
          title: "All printers deleted successfully",
          subTitle: ""
        });
      }
    } catch (error) {
      setDialogText({
        title: "Error Retrying Deletion",
        subTitle: "Something went wrong while retrying the deletion."
      });
    } finally {
      setIsRetryLoading(false);
    }
  };

  // Check if there are any failed printers.
  const hasFailedPrinters = printerStatus ? printerStatus.some(printer => !printer.status) : false;

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogTitle
        sx={{m: 0, p: 2}}
        id="customized-dialog-title"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box className={s.modal_item}>
          {subTitle && (
            <Box className={s.verification_block}>
              <Typography component="div" textAlign="center" sx={{color: 'red'}}>
                {subTitle}
              </Typography>
            </Box>
          )}
          {!isShowAskDialog && isLoading && (
            <Box sx={{display: 'flex', justifyContent: 'center', my: 2}}>
              <CircularProgress/>
            </Box>
          )}
          {!isShowAskDialog && !isLoading && printerStatus && (
            <>
              <PrinterStatusTable printerStatus={printerStatus}/>
              {hasFailedPrinters && (
                <Box sx={{display: 'flex', justifyContent: 'center', my: 1}}>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleRetry}
                    disabled={isRetryLoading}
                    sx={{...modalButtonStyle}}
                  >
                    {isRetryLoading ? <CircularProgress size={24}/> : "Retry Failed Printers"}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {isShowAskDialog && (
          <>
            <Button
              variant="outlined"
              sx={{...modalButtonStyle}}
              onClick={handleDelete}
              id={s.verification_button}
            >
              <CheckOutlinedIcon color="success"/>
              Yes
            </Button>
            <Button
              variant="outlined"
              sx={{...modalButtonStyle}}
              onClick={handleClose}
              id={s.verification_button}
            >
              <DoDisturbOutlinedIcon color="error"/>
              No
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
}
