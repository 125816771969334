import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useSessionExpiry = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return;
    }

    let intervalId: ReturnType<typeof setInterval>;

    const checkSession = async () => {
      try {
        await getAccessTokenSilently();
      } catch (error) {
        window.location.reload();
      }
    };

    checkSession();
    intervalId = setInterval(checkSession, 60000); // every minute

    return () => clearInterval(intervalId);
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);
};

export default useSessionExpiry;
