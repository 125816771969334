import {useEffect, useState, Fragment} from "react";
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Alert, Box, Fade, Menu, MenuItem, Snackbar} from "@mui/material";
import TextField from '@mui/material/TextField';
import CopyToClipboardButton from "../CopyToClipboardButton";
import s from "./PrinterForm.module.css"
import {utils_instance} from "../../../utils/Utils";
import {ApiInstance} from "../../../api/api";
import {printerDataType} from "../../../types/printerTypes";
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import * as React from "react";
import PrinterForm from "./PrinterForm";


const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

export interface InitialUpdateFormProps {
  containerHierarchy: [];
  initialFormValues: printerDataType[] | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export interface UnavailableOperationProps {
  operationName: string
  isOpen: boolean;
  onClose: () => void;
}

function UnavailableOperation(props: UnavailableOperationProps) {
  const {operationName, isOpen, onClose} = props
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle>
        <Box>
          {operationName}
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon/>
      </IconButton>
      <DialogContent dividers>
        <Box className={s.form_box_item}>
          <Box className={s.box_device_name}>
            <BlockRoundedIcon fontSize="large" color="error"/>
            <Typography component={'div'} textAlign="center">
              Action is not available for multiple printers. Please select only one printer!
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className={s.form_print_button}
          variant='outlined'
          onClick={onClose}
        >Close</Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default function UpdatePrinterForm(props: InitialUpdateFormProps) {
  const selected_entity: printerDataType[] | undefined = props.initialFormValues;
  const initialFormValue: printerDataType | undefined = props.initialFormValues?.[0];

  return (
    <>
      {selected_entity?.length !== 1 ? (
        <UnavailableOperation
          operationName={"Update Printer"}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      ) : (
        <PrinterForm
          containerHierarchy={props.containerHierarchy}
          initialFormValue={initialFormValue}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      )}
    </>
  )
}